var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"route-name":"SettingsSecurityControls","route-action":"list","navbar-title":_vm.$t('settings.securityControl.buttonLabel'),"navbar-back-route-without-history":{ name: 'Settings' },"main-list-filter-label":_vm.$t('settings.securityControl.filterLabel'),"main-fist-scroll-to-selected":"","storeData":{
            mainListItems: {
                action: 'tools/fetchSecurityControlItems',
                payload: { force: true },
                options: null,
                itemText: 'title',
                customized: {
                    property: 'customized',
                    color: 'primary lighten-4',
                },
            },
            mainItemById: {
                action: 'tools/fetchSecurityControlById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'tools/editSecurityControl',
                payload: null,
                options: null,
                refetch: true,
                hideButton: true
            },
            add: {
                action: 'tools/createSecurityControlItem',
                payload: null,
                options: null,
            },
            delete: {
                action: 'tools/deleteSecurityControl',
                payload: null,
                options: null,
                hideButton: true
            },
            findUsage: {
                action: 'tools/findUsageSecurityControl',
                payload: null,
                options: null,
                hideButton: true
            },
            replace: {
                action: 'tools/replaceSecurityControlInTool',
                options: null,
                hideButton: true
            },
        },"newItemModelFields":{
            controlId: null,
            customized: true,
            title: null,
            locale: _vm.currentLanguage,
            translations: _vm.defaultTranslations,
        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('EditorMask',{key:x.id,attrs:{"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onDeleteItem":x.onDeleteItem,"onFindUsage":x.onFindUsage,"onCreateItem":x.onCreateItem},model:{value:(x.selectedMainItem),callback:function ($$v) {_vm.$set(x, "selectedMainItem", $$v)},expression:"x.selectedMainItem"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }